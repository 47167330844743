import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { find } from 'lodash';
import { Subscription } from 'rxjs';

import { CartItem } from '../../../../ecomm/types/cart.types';
import {
  MenuItem,
  MenuModifierGroup,
  MenuModifierGroupItem
} from '../../../../ecomm/types/store-info.types';
import { Modifiers } from '../../../common';
import { ModifierGroupFormData } from '../modifier-elements/modifier-element.component';

@Component({
  selector: 'wri-mod-group',
  templateUrl: './mod-group.component.html',
  styleUrls: ['./mod-group.component.scss']
})
export class ModGroupComponent implements OnInit, OnDestroy {
  @Input() modifierGroup!: MenuModifierGroup;
  @Input() isModGroupFirstAndRequired!: boolean;
  @Input() initialValues: Partial<ModifierGroupFormData> = {};
  @Input() public selectedLineItem!: CartItem;
  @Input() public startPDPValidations = false;
  @Output()
  resetPDPValidationsEmit = new EventEmitter();
  @Output()
  valuesChanged = new EventEmitter<ModifierGroupFormData>();

  modifierSelectionForm: FormGroup = new FormGroup({});
  public modifierGroupFormData: ModifierGroupFormData | undefined;
  @Output()
  allSelectionsValid = new EventEmitter<boolean | null>();

  public systemDefined = 0;
  public userDefined = 0;
  /**
   * @ignore
   */
  private subscription = new Subscription();

  /**
   * @ignore
   */
  constructor(private fb: FormBuilder) {}

  resetPDPValidations() {
    this.resetPDPValidationsEmit.emit(false);
  }

  @HostListener('window:scroll')
  checkScroll() {
    const scrollContainer = document.getElementById(
      `header-${this.modifierGroup.id}`
    );
    const isContainerExpanded =
      document
        .getElementById(`mod-${this.modifierGroup.id}`)
        ?.classList.contains('show') || false;
    const sticky = (scrollContainer as HTMLElement).offsetTop;
    if (window.pageYOffset >= sticky && isContainerExpanded) {
      scrollContainer?.classList.add('nav-shadow');
    } else {
      scrollContainer?.classList.remove('nav-shadow');
    }
  }

  areSelectionsValid(): boolean | null | undefined {
    const validityOfModifiers: boolean[] = [];
    this.modifierGroupFormData?.modifiers?.forEach((modifier) => {
      validityOfModifiers.push(
        modifier.isValid !== undefined ? modifier.isValid : true
      );
    });
    const sumOfAllSelections = this.systemDefined + this.userDefined;
    let isValid = false;
    if (
      this.modifierGroupFormData?.modifiers &&
      this.modifierGroupFormData.modifiers.length >=
        this.modifierGroup.minSelectionsRequired
    ) {
      if (this.modifierGroup.quantitiesEnabled) {
        isValid =
          sumOfAllSelections === this.modifierGroup.aggregateQuantity &&
          validityOfModifiers.reduce((res, cur) => res && cur, true);

        this.allSelectionsValid.emit(isValid);

        return isValid;
      }
      // skip above logic to check with aggregateQuantity if quantitiesEnabled: false
      else {
        isValid = validityOfModifiers.reduce((res, cur) => res && cur, true);

        this.allSelectionsValid.emit(isValid);
        return isValid;
      }
    }

    return isValid;
  }

  isSimpleModifier(modGroupElement: MenuModifierGroupItem): boolean {
    if (modGroupElement.item === null) {
      return true;
    }
    if (modGroupElement.modifier === null) {
      return false;
    }
    return false;
  }

  ngOnInit(): void {
    this.modifierGroup.modifierGroupElements =
      this.filterModGroupElementsByAvailability(
        this.modifierGroup.modifierGroupElements
      );
    this.modifierSelectionForm = this.createForm();
    this.handleFormUpdate(this.modifierSelectionForm.value);
    this.subscription.add(
      this.modifierSelectionForm.valueChanges.subscribe((values) =>
        this.handleFormUpdate(values)
      )
    );
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  public getModifierGroupFromCart(menuModGrpId: string) {
    const hasModifierInCart = find(this.selectedLineItem?.modifierGroups, [
      'modifierGroupId',
      menuModGrpId
    ]);
    return hasModifierInCart;
  }

  public updateModalModifiers(
    hasSimpleOrComplexModifierInCart: Modifiers,
    simpleOrComplexModifier: MenuItem
  ) {
    if (
      hasSimpleOrComplexModifierInCart &&
      hasSimpleOrComplexModifierInCart.modifierGroups
    ) {
      simpleOrComplexModifier?.modifierGroups?.forEach((eachComplexModGrp) => {
        const hasComplexModGrpInCart = find(
          hasSimpleOrComplexModifierInCart.modifierGroups,
          ['modifierGroupId', eachComplexModGrp.id]
        );
        //Selected Modal modifier
        if (hasComplexModGrpInCart) {
          eachComplexModGrp.modifierGroupElements?.forEach(
            (modGrpElement: MenuModifierGroupItem) => {
              if (modGrpElement?.modifier) {
                const hasModalModInCart = find(
                  hasComplexModGrpInCart.modifiers,
                  ['modifierId', modGrpElement.modifier.id]
                );
                if (hasModalModInCart) {
                  modGrpElement.modifier.isDefault = true;
                } else {
                  modGrpElement.modifier.isDefault = false;
                }
              }
            }
          );
        } else {
          //UnSelected Modal modifier
          eachComplexModGrp.modifierGroupElements?.forEach(
            (modGrpElement: MenuModifierGroupItem) => {
              if (modGrpElement?.modifier) {
                modGrpElement.modifier.isDefault = false;
              }
            }
          );
        }
      });
    }
  }

  public updateSimpleOrComplexModifierWithCart(
    simpleOrComplexModifier: MenuItem,
    modifierGroup: MenuModifierGroup
  ) {
    const modifierFromCart = this.getModifierGroupFromCart(modifierGroup.id);
    if (modifierFromCart) {
      const hasSimpleOrComplexModifierInCart = find(
        modifierFromCart.modifiers,
        ['modifierId', simpleOrComplexModifier.id]
      );
      if (hasSimpleOrComplexModifierInCart) {
        this.updateModalModifiers(
          hasSimpleOrComplexModifierInCart,
          simpleOrComplexModifier
        );
        simpleOrComplexModifier.isDefault = true;
        return simpleOrComplexModifier;
      } else {
        simpleOrComplexModifier.isDefault = false;
        return simpleOrComplexModifier;
      }
    }
    return simpleOrComplexModifier;
  }

  isSelected(id: string): boolean {
    const item =
      this.modifierGroupFormData?.modifiers?.filter((s) => s.modifierId === id)
        ?.length ?? 0;
    return item > 0;
  }

  getSelectedQuantities() {
    return (
      this.modifierGroupFormData?.modifiers?.reduce(
        (a, b) => a + (b['quantity'] || 0),
        0
      ) ?? 0
    );
  }

  handleModifierQuantitySelections(values: {
    systemDefined: number;
    userDefined: number;
  }) {
    const { systemDefined, userDefined } = values;
    this.systemDefined = systemDefined;
    this.userDefined = userDefined;
  }

  /**
   * @ignore
   */
  private createForm(): FormGroup {
    return this.fb.group({
      modifiers: [this.initialValues.modifiers]
    });
  }

  /**
   * @ignore
   */
  private handleFormUpdate(values: ModifierGroupFormData) {
    this.modifierGroupFormData = values;
    this.valuesChanged.emit({
      ...values
    });
  }

  private filterModGroupElementsByAvailability(
    modGroupElements: MenuModifierGroupItem[]
  ): MenuModifierGroupItem[] {
    const filteredModGroupElements: MenuModifierGroupItem[] = [];
    modGroupElements.forEach((modGroupElement) => {
      if (
        (modGroupElement.modifier &&
          modGroupElement.modifier.availableInSchedule) ||
        (modGroupElement.item && modGroupElement.item.availableInSchedule)
      ) {
        const newModGroupElement: MenuModifierGroupItem = {
          ...modGroupElement
        };

        if (
          modGroupElement.modifier &&
          !modGroupElement.modifier.availableInSchedule
        ) {
          // newModGroupElement.modifier = null;
          // what todo if modifier is not available  and item is available ? Unable to make it null
        }
        if (modGroupElement.item && !modGroupElement.item.availableInSchedule) {
          newModGroupElement.item = null;
        }
        filteredModGroupElements.push(newModGroupElement);
      }
    });
    return filteredModGroupElements;
  }
}
