import { Component, Input } from '@angular/core';

import { Address } from '../../../../ecomm/types/address';

@Component({
  selector: 'wri-address-link',
  template: `
    <div class="address-link-wpr">
      <strong *ngIf="showName && name" data-testid="wri-addr-name">
        {{ name }}<br />
      </strong>
      <span *ngFor="let line of displayLines; let last = last">
        {{ line }}<br *ngIf="!last" />
      </span>
      <br />

      <div class="directions-phone-number-wpr">
        <a *ngIf="mapUrl && handOffMode?.toLowerCase()==='carryout'"
           [href]="mapUrl" target="_blank" rel="noopener"
           class="address-link" [attr.aria-label]="linkLabel">
          <wri-icon icon="wri-directions"></wri-icon>
          <span wriExternalLinks class="directions-text">Get Directions</span>
        </a>
        <wri-phone-number
            *ngIf="showPhoneNumber"
            [phoneNumber]="address?.phoneNumber"
        ></wri-phone-number>
      </div>
    </div>
  `,
  styleUrls: ['./address-link.component.scss']
})
export class AddressLinkComponent {
  private static readonly MAPS_BASE_URL = 'https://www.google.com/maps/search/';
  private static readonly MAPS_QUERY_STR = '?api=1&query=';
  private static readonly OPEN_IN_NEW_TAB_DISCLAIMER = 'Opens in a new tab.';
  private static readonly GET_DIRECTIONS_TO = 'Get directions to';

  @Input()
  public name?: string;

  @Input()
  public address!: Address;

  @Input()
  public handOffMode!: string;

  @Input()
  public showName = false;

  @Input() lines = 1;

  @Input() public showPhoneNumber = false;

  get mapUrl() {
    return (
      AddressLinkComponent.MAPS_BASE_URL +
      AddressLinkComponent.MAPS_QUERY_STR +
      [
        this.fullStreetAddress,
        this.address?.locality,
        this.address?.region,
        this.address?.postalCode,
        this.address?.countryCode,
        this.address?.phoneNumber
      ].join(' ')
    );
  }

  get linkLabel() {
    return this.name
      ? [
          AddressLinkComponent.GET_DIRECTIONS_TO,
          `${this.name} at`,
          `${this.fullStreetAddress},`,
          `${this.address?.locality}.`,
          AddressLinkComponent.OPEN_IN_NEW_TAB_DISCLAIMER
        ].join(' ')
      : [
          AddressLinkComponent.GET_DIRECTIONS_TO,
          `${this.fullStreetAddress},`,
          `${this.address?.locality}.`,
          AddressLinkComponent.OPEN_IN_NEW_TAB_DISCLAIMER
        ].join(' ');
  }

  get fullStreetAddress(): string {
    return [this.address?.streetAddress, this.address?.secondaryAddress]
      .filter((s) => !!s)
      .join(', ');
  }

  get displayLines(): string[] {
    switch (this.lines) {
      case 1:
        return [
          `${this.fullStreetAddress}, ${this.address.locality}, ${this.address.region} ${this.address.postalCode}`
        ];
      case 2:
        return [
          `${this.fullStreetAddress}`,
          `${this.address.locality}, ${this.address.region} ${this.address.postalCode}`
        ];
      case 3:
        return [
          this.address.streetAddress,
          this.address.secondaryAddress,
          `${this.address.locality}, ${this.address.region} ${this.address.postalCode}`
        ].filter(Boolean) as string[];
      default:
        return [];
    }
  }
}
