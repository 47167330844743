<wri-page-loading-indicator *ngIf="(isLoading$ | async) || isLoading"></wri-page-loading-indicator>
<wri-partial-outage-modal></wri-partial-outage-modal>
<!-- error state -->
<wri-generic-error
  *ngIf="storeInfoState | shouldShowLocationError; else checkout"
></wri-generic-error>

<ng-template #checkout>
  <div class="checkout-container">
    <div class="my-checkout-bag grid">
      <div class="return-to-cart-wrapper">
        <section class="return-to-cart-section">
          <div class="return-to-cart">
            <a
              wriFocusOutline
              tabindex="0"
              data-testid="return-to-cart-anchor"
              (click)="currentUrlService.goToMyBag()"
            >
              <wri-icon
                icon="wri-arrow-back"
                class="arrow-left-icon"
              ></wri-icon>
              <span data-cy="returnToCart" class="breadcrumb-text"
                >Return to Cart</span
              >
            </a>
          </div>
        </section>
        <section class="products checkout">Checkout</section>
        <section class="products contact-info">
          <ng-container *ngIf="isDelivery()">
            <div id="delivery-instructions-form">
              <wri-delivery-instructions-form
                (valuesChanged)="handleDeliveryInstuctionsData($event)"
                [isFormValid]="deliveryInstructionsFormValid"
                [initialValues]="deliveryInstructionData"
                [cart]="cart"
              ></wri-delivery-instructions-form>
            </div>
          </ng-container>
          <div id="guest-signup-form">
            <wri-guest-signup-form
              (valuesChanged)="handleContactInfoData($event)"
              [isFormValid]="contactInfoFormValid"
              [canShowErrors]="
                (!contactInfoFormValid && expressPayTouched) ||
                (!expressPayTouched && !contactInfoFormValid)
              "
              (emitLoginClick)="handleLogin()"
              [userDetails]="userDetails"
              [passwordRequirements]="passwordRequirements$ | async"
              [emailDomainValidations]="emailDomainValidations$ | async"
              >
            </wri-guest-signup-form>
          </div>
        </section>
      </div>
      <div class="checkout-sidebar-wrapper">
        <div class="checkout-sidebar">
          <hr class="edge-to-edge-wri-divider mobile-only">
          <section class="handoff spacing">
            <wri-cart-address
              [locationDetails]="locationDetailsState?.locationDetails | getLocationDetails : cart?.handoffMode"
              [cart]="cart"
              [showPhoneNumber]="showPhoneNumber"
              [showSecondaryAddress]="false"
            ></wri-cart-address>
          </section>
          <hr class="edge-to-edge-wri-divider">
          <section class="fulfillment-time spacing">
            <wri-cart-fulfillment-time
              [isReadOnly]="true"
              [fulfillmentTime]="fulfillmentTime"
              [handoffMode]="cart?.handoffMode"
              [ftForLater]="laterTime"
              [cartFulfillmentTime]="cart?.fulfillmentTime"
              [cartReadyTimes]="cart?.readyTimes"
              [cartASAP]="cart?.asap"
            >
            </wri-cart-fulfillment-time>
          </section>
          <hr class="edge-to-edge-wri-divider">
          <section class="cart-line-items">
            <wri-review-order-items
              [cart]="cart"
              [isReadOnly]="true"
            ></wri-review-order-items>
          </section>
          <hr class="edge-to-edge-wri-divider">
          <section
            *ngIf="isTippingAvailable()"
            class="tip-selector tip-selection spacing"
          >
            <wri-tip
              [tipOptions]="(regionalConfig$ | async)?.tipConfiguration
                  | tipOptions : cart?.handoffMode : cart?.subtotal | pick : 'tipOptions'"
              [handOffMode]="this.cart?.handOffMode"
              [cartFeatureState]="cartState"
              [currencyType]="(regionalConfig$ | async)?.tipConfiguration
                  | tipOptions : cart?.handoffMode : cart?.subtotal | pick : 'currencyType'"
              [fromCheckout]="true"
            />
          </section>
          <hr class="edge-to-edge-wri-divider">
          <section class="price-summary spacing">
            <wri-cart-price-summary
              [cart]="cart"
              [showRoundUpFeesSelection]="showRoundUp()"
              [roundupCharityData]="roundupCharity$ | async"
            ></wri-cart-price-summary>
          </section>
          <hr class="edge-to-edge-wri-divider">
          <div class="place-order-wrapper-lg">
            <section class="place-order spacing" id="order-button-section">
              <button
                *ngIf="!changePlaceOrderBtn"
                (click)="onPlaceOrderBtnClick()"
                [disabled]="isLoading"
                class="wri-btn wri-btn-primary"
              >
                Place Order
              </button>

              <ng-container *ngIf="enableNewPaymentFlow">
                <ng-container *ngTemplateOutlet="gpayTemplate"></ng-container>
                <ng-container *ngTemplateOutlet="applePayTemplate"></ng-container>
                <ng-container *ngTemplateOutlet="payPalTemplate"></ng-container>
              </ng-container>

              <div class="legal-disclaimer">
                By placing your order, you agree to our
                <a
                  href="https://www.wingstop.com/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>
                and
                <a
                  href="https://www.wingstop.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy </a
                >.
              </div>
            </section>
          </div>
        </div>
      </div>
      <div id="payment-wrapper" class="payment-wrapper">
        <section class="products payment-section">
          <h3 class="form-title">Payment</h3>
          <div
            class="regular-payments"
            [ngClass]="
              (!paymentFormValid && giftCardsValid && contactInfoFormValid && placeOrderBtnClicked) ||
              (!giftCardsValid && contactInfoFormValid)
                ? 'invalid-form'
                : ''
            "
          >
            <wri-payment-method
              [storeInfoData]="storeInfoState?.storeInfo"
              [cart]="cart"
              (valid)="handlePaymentDataValidity($event)"
              (validGiftCards)="handleGiftCardsValidity($event)"
              (selected)="handlePaymentDataSelected($event)"
              (expressPay)="handleExpressPay($event)"
              (expressPayClicked)="handleExpressPayClicked($event)"
              [expressPayPrerequisitesMet]="
                isDelivery()
                  ? (deliveryDetailsAreValid$ | async) &&
                    (guestDetailsAreValid$ | async)
                  : (guestDetailsAreValid$ | async)
              "
              [paymentsSelected]="paymentsSelectedObservable$ | async"
              [userLoggedIn]="userLoggedIn"
              [vaultedCards]="vaultedCards$ | async"
              [regionalConfigState]="(regionalConfig$ | async)"
              (payWithDigitalWallet)="payWithDigitalWallet($event)"
              [resetDigitalWalletRadioButton]="!changePlaceOrderBtn"
            ></wri-payment-method>
          </div>
          <span
            class="form-error"
            *ngIf="!paymentFormValid && giftCardsValid && contactInfoFormValid && placeOrderBtnClicked"
          >
            Please select a payment method
          </span>
          <span
            class="form-error"
            *ngIf="(!giftCardsValid) && contactInfoFormValid"
          >
            Insufficient balance. Please add an additional payment method.
          </span>
        </section>
      </div>
      <div class="place-order-wrapper">
        <section class="place-order spacing" id="order-button-section">
          <button
            *ngIf="!changePlaceOrderBtn"
            (click)="onPlaceOrderBtnClick()"
            [disabled]="isLoading"
            class="wri-btn wri-btn-primary"
          >
            Place Order
          </button>

          <ng-container *ngIf="enableNewPaymentFlow">
            <ng-container *ngTemplateOutlet="gpayTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="applePayTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="payPalTemplate"></ng-container>
          </ng-container>

          <div class="legal-disclaimer">
            By placing your order, you agree to our
            <a
              href="https://www.wingstop.com/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>
            and
            <a
              href="https://www.wingstop.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy </a
            >.
          </div>
        </section>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #splitPaymentsModal let-close="close" let-dismiss="dismiss">
  <wri-common-modal [close]="close" [dismiss]="dismiss">
    <div class="wri-modal__wrapper">
      <span class="wri-modal__title">
        Oops! We Can't Split Payments
      </span>
      <span class="wri-modal__description">
        {{ splitPaymentsModalDescription }}
      </span>
      <div class="wri-modal__btn-wrapper">
        <button
          data-testid="permission-modal-action"
          class="wri-btn wri-btn-primary"
          aria-label="Ok"
          (click)="modalService.dismissAll()">
          Ok
        </button>
      </div>
    </div>
  </wri-common-modal>
</ng-template>

<ng-template #gpayTemplate >
<div
  [ngStyle]="!(changePlaceOrderBtn && digitalWalletPaymentType$.value ==='gpay' )&& { display: 'none' }"
  class="full-width">
  <wri-google-pay-payment-method
    [totalPrice]="cart?.total || 0"
    [expressPayPrerequisitesMet]="isDelivery()? (deliveryDetailsAreValid$ | async) && (guestDetailsAreValid$ | async): (guestDetailsAreValid$ | async)"
    [paymentsSelected]="paymentsSelectedObservable$ | async"
    [isPaymentMethodSupported]="
          ('googlePay'
            | isPaymentMethodSupported
              : storeInfoState?.storeInfo?.storeDetails.handoffModes
              : cart?.handoffMode).hasValidPayment
        "
    (expressPay)="handleExpressPay($event)"
    (expressPayClicked)="handleExpressPayClicked('Google Pay')"
  ></wri-google-pay-payment-method>
</div>
</ng-template>

<ng-template #payPalTemplate>
  <div
       [ngStyle]="!(changePlaceOrderBtn && digitalWalletPaymentType$.value ==='payPal' )&& { display: 'none' }"
       class="full-width">
    <wri-paypal-payment-method
      [totalPrice]="cart?.total || 0"
      [expressPayPrerequisitesMet]="isDelivery()? (deliveryDetailsAreValid$ | async) && (guestDetailsAreValid$ | async): (guestDetailsAreValid$ | async)"
      [paymentsSelected]="paymentsSelectedObservable$ | async"
      [isPaymentMethodSupported]="
          ('payPal'
            | isPaymentMethodSupported
              : storeInfoState?.storeInfo?.storeDetails.handoffModes
              : cart?.handoffMode).hasValidPayment
        "
      (expressPay)="handleExpressPay($event)"
      (expressPayClicked)="handleExpressPayClicked('PayPal')"
    ></wri-paypal-payment-method>
  </div>
</ng-template>

<ng-template #applePayTemplate>
  <div
       [ngStyle]="!(changePlaceOrderBtn && digitalWalletPaymentType$.value ==='applePay' )&& { display: 'none' }"
       class="full-width">
    <wri-apple-pay-payment-method
      [cart]="cart"
      [expressPayPrerequisitesMet]="isDelivery()? (deliveryDetailsAreValid$ | async) && (guestDetailsAreValid$ | async): (guestDetailsAreValid$ | async)"
      [paymentsSelected]="paymentsSelectedObservable$ | async"
      [isPaymentMethodSupported]="
          ('applePay'
            | isPaymentMethodSupported
              : storeInfoState?.storeInfo?.storeDetails.handoffModes
              : cart?.handoffMode).hasValidPayment
        "
      (expressPay)="handleExpressPay($event)"
      (expressPayClicked)="handleExpressPayClicked('Apple Pay')"
    ></wri-apple-pay-payment-method>
  </div>
</ng-template>
