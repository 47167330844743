import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getOr } from 'lodash/fp';
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  from,
  map,
  Observable,
  of,
  startWith,
  Subject,
  Subscription,
  switchMap,
  tap
} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { Cart } from '../../../../ecomm/types/cart.types';
import { FulfillmentTimes } from '../../../../ecomm/types/fulfillment-times.types';
import {
  Order,
  OrderSubmitAction,
  OrderSubmitRequest, OrderSubmitRequestPayment
} from '../../../../ecomm/types/order.types';
import {
  DeliveryInstructionsDataWithErrors,
  DeliveryInstructionsFormData
} from '../delivery-instructions-form/delivery-instructions-form.component';
import { GuestSignupFormDataWithErrors } from '../guest-signup-form/guest-signup-form.component';
import { PaymentMethodComponent } from '../payments/payment-method/payment-method.component';
import { TipSelectionPipe } from '../../../common/pipes/tip-selection.pipe';
import {
  FiservPaymentFailedType,
  OtherPaymentFailedType,
  PaymentInfo,
  PaymentMethodLike,
  PaymentType
} from '../payments/payment-method/payment-method.types';
import jwtDecode from 'jwt-decode';
import { includes } from 'lodash';
import { CustomerVaultedCards } from '../../../../ecomm/types/payment.types';
import {
  Customer,
  DeliveryAddressPostRequest
} from '../../../../ecomm/types/customer.types';
import { MaybeResponse } from '../../../../ecomm/types/maybe-response';
import { FeatureFlagService } from '../../../../ecomm/utils/feature-flag/feature-flag.service';
import {
  RegionalConfigurationFeature,
  RegionalConfigurationFeatureState
} from '../../../../ecomm/store/features/regional-configuration';
import {
  AuthFeature,
  AuthFeatureState
} from '../../../../ecomm/store/features/auth';
import {
  CartFeature,
  CartFeatureState
} from '../../../../ecomm/store/features/cart';
import {
  StoreInfoFeature,
  StoreInfoFeatureState
} from '../../../../ecomm/store/features/store-info';
import { HandoffMode } from '../../../../ecomm/types/selected-handoff-mode.types';
import {
  OrderFeature,
  OrderFeatureState
} from '../../../../ecomm/store/features/order';
import { AnalyticsService } from '../../../../ecomm/providers/legacy-providers/analytics.service';
import { NAVIGATOR } from '../../../../ecomm/providers/navigator/navigator.provider';
import {
  SCRIPT_LOADER,
  ScriptLoader
} from '../../../../ecomm/providers/script-loader/script-loader.provider';
import { CurrentUrlService } from '../../../../ecomm/utils/current-url/current-url.service';
import { getLaterTime } from '../../../../ecomm/utils/moment';
import { NotificationService } from '../../../../ecomm/utils/notification/notification.service';
import { ReCaptchaService } from '../../../../ecomm/utils/recaptcha/recaptcha.service';
import { CustomerWorkflowService } from '../../../../ecomm/workflows/customer/customer-workflow.service';
import { OrderWorkflowService } from '../../../../ecomm/workflows/order/order-workflow.service';
import { PaymentWorkflowService } from '../../../../ecomm/workflows/payment/payment-workflow.service';
import {
  ACTIVATED_ROUTE_SNAPSHOT,
  CheckoutPageService,
  PartialOutageModalComponent
} from '../../../common';
import { getCategoryFromItemId } from '../../../../ecomm/workflows/store-info/store-info.utilities';
import {
  Config,
  CONFIG
} from '../../../../ecomm/providers/config/config.provider';
import { addSpaceToBodyWithStickyFooter } from '../../../../ecomm/utils/dom-manipulations';
import { AuthService } from '../../../../ecomm/utils/auth/auth.service';
import { PasswordRequirementsWorkflowService } from '../../../../ecomm/workflows/password-requirements/password-requirements-workflow.service';
import { PasswordRequirements } from '../../../../ecomm/types/password-requirements.types';
import { RoundupCharity } from '../../../../ecomm/types/roundup-charity.types';
import { RoundupCharityWorkflowService } from '../../../../ecomm/workflows/roundup-charity/roundup-charity.workflow.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailDomainValidationWorkflowService } from '../../../../ecomm/workflows/email-domain-validation/email-domain-validation-workflow.service'
import { EmailDomainValidation } from '../../../../ecomm/types/email-domain-validation.types';
import { LocationDetailsFeature, LocationDetailsFeatureState } from "../../../../ecomm/store/features/location-details";

/* eslint-disable @typescript-eslint/no-explicit-any */
declare const Ravelin: any;

type CheckoutFormName =
  | 'deliveryInstructionsForm'
  | 'signupForm'
  | 'paymentForm';

@Component({
  selector: 'wri-checkout',
  templateUrl: './connected-checkout.component.html',
  styleUrls: ['./connected-checkout.component.scss']
})
export class ConnectedCheckoutComponent
  implements OnInit, OnDestroy, AfterViewChecked {
  public cart: Cart | null = null;
  public cartState: CartFeatureState | null = null;
  public storeInfoState: StoreInfoFeatureState | null = null;
  public orderState: OrderFeatureState | null = null;
  public authState: AuthFeatureState | null = null;
  public fulfillmentTime: FulfillmentTimes | null = null;
  public numItems = 0;
  public isLoading = true;
  public laterTime: string | undefined = '';
  // trigger inline field errors in respective forms
  public deliveryInstructionsFormValid = true;
  public paymentFormValid = true;
  public giftCardsValid = true;
  public paymentSelected = false;
  userLoggedIn = false;
  public deliveryInstructionData: DeliveryInstructionsFormData | null = null;

  // variables to be used to
  public contactInfoFormValid = true;
  public showPhoneNumber = false;
  @ViewChild(PaymentMethodComponent)
  public paymentMethod!: PaymentMethodComponent;
  @ViewChild('splitPaymentsModal')
  splitPaymentsModal!: TemplateRef<HTMLElement>;
  public splitPaymentsModalDescription: string | undefined;
  public ifExpressPay = false;
  public placeOrderBtnClicked = false;
  private contactInfoData$: Subject<GuestSignupFormDataWithErrors> =
    new Subject();
  private deliveryFormInfoData$: Subject<DeliveryInstructionsDataWithErrors> =
    new Subject();

  public guestDetailsAreValid$ = this.contactInfoData$.pipe(
    map((form) => {
      return !form.errors || !Object.keys(form.errors || {}).length;
    }),
    startWith(false)
  );
  public deliveryDetailsAreValid$ = this.deliveryFormInfoData$.pipe(
    map((form) => {
      return !form.errors || !Object.keys(form.errors || {}).length;
    }),
    startWith(false)
  );
  private contactInfoData: Partial<GuestSignupFormDataWithErrors> | undefined;
  private subscription = new Subscription();
  @ViewChild(PartialOutageModalComponent) partialOutageModalComponent:
    | PartialOutageModalComponent
    | undefined;
  private deliveryInstructionsData:
    | Partial<DeliveryInstructionsDataWithErrors>
    | undefined;

  private userEmail: string | undefined | null;
  private isUserLoggedIn = false;
  private isPlaceOrderCalled = false;
  numberOfTimesPlaceOrderTriggered = 0;
  public vaultedCards$: Observable<CustomerVaultedCards | null> | undefined;

  public regionalConfigState: RegionalConfigurationFeatureState | null = null;

  public regionalConfig$ = this.store
    .select(RegionalConfigurationFeature.selectRegionalConfigurationState)
    .pipe(filter<RegionalConfigurationFeatureState>(Boolean));

  userDetails: Customer | null = null;

  public passwordRequirements$ = new BehaviorSubject<PasswordRequirements[]>(
    []
  );

  fallBackPasswordRequirement = [
    {
      id: 'minLength',
      description: '8 characters',
      allowed: '',
      count: 8
    }
  ];
  fallBackRoundupCharityResponse = {
    charityName: 'Wingstop Charities',
    roundupDescription:
      'Round up your bill to the nearest dollar and donate to support our communities!',
    modalImageUrl:
      'https://cdn.bfldr.com/NDQASMJ1/as/n5nh8665nqbkprh4wvnr6s/wingstopcharities-logo-green?auto=webp&format=png',
    modalTitle: 'Our Commitment',
    modalDescription:
      'Wingstop Charities serves the community through community grants, sponsorships, and service. Our mission is to amplify the flavor of our communities through service. Wingstop Charities’ five focus areas are education, food, sports, entrepreneurship, and environment.'
  };
  public roundupCharity$ = new BehaviorSubject<RoundupCharity>(
    this.fallBackRoundupCharityResponse
  );

  enableNewPaymentFlow = false;

  public emailDomainValidations$ = new BehaviorSubject<EmailDomainValidation>(null);
  public locationDetailsState: LocationDetailsFeatureState | null = null;
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
  private paymentsSelectedSubject = new BehaviorSubject<PaymentMethodLike[]>(
    []
  );
  paymentsSelectedObservable$ = this.paymentsSelectedSubject.asObservable();
  changePlaceOrderBtn = false;
  digitalWalletPaymentType$ = new BehaviorSubject<string | null>(null);
  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private pageService: CheckoutPageService,
    public currentUrlService: CurrentUrlService,
    private orderService: OrderWorkflowService,
    private notificationService: NotificationService,
    @Inject(SCRIPT_LOADER)
    private scriptLoaderProvider: ScriptLoader,
    private reCaptchaService: ReCaptchaService,
    @Inject(NAVIGATOR)
    private navigator: Navigator,
    private userAccountService: CustomerWorkflowService,
    private analyticsService: AnalyticsService,
    private paymentWorkflowService: PaymentWorkflowService,
    private router: Router,
    private authService: AuthService,
    private passwordRequirementsService: PasswordRequirementsWorkflowService,
    private roundupCharityService: RoundupCharityWorkflowService,
    private emailDomainValidationService: EmailDomainValidationWorkflowService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    public featureFlagService: FeatureFlagService,
    @Inject(CONFIG) private readonly config: Config
  ) { }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  async ngOnInit() {
    addSpaceToBodyWithStickyFooter('checkout-page');

    try {
      //load grecaptcha in head tag
      this.scriptLoaderProvider(this.reCaptchaService.getRecaptchaURL()).then(
        (script) => {
          console.debug('grecaptcha SCRIPT ', script);
        }
      );
      this.setNumOfItemsInCart();
      this.subscription.add(
        this.route.data
          .pipe(
            filter((data) => data[ACTIVATED_ROUTE_SNAPSHOT]),
            tap(() => (this.isLoading = true)),
            switchMap((data) =>
              this.pageService.load$(data[ACTIVATED_ROUTE_SNAPSHOT])
            )
          )
          .subscribe(() => {
            this.isLoading = this.isPlaceOrderCalled;
          })
      );
      this.subscribeToLocationDetailsState();
      this.subscribeToStoreInfoState();
      this.subscribeToCartState();
      await this.subscribeToRegionalConfigState();
      this.enableNewPaymentFlow = await this.featureFlagService.getFeatureFlagValue(
        'enableDigitalWalletPlaceOrderButton',
        'feature_enable_digital_wallet_place_order_button'
      );
      this.subscribeToAuthState();
      this.analyticsService.logGaEvent({
        event: 'begin_checkout',
        ecommerce: {
          items:
            this?.cart?.items.map((item) => ({
              item_category:
                getCategoryFromItemId(
                  item.productId,
                  this.storeInfoState?.storeInfo?.categories
                ) || '',
              item_id: item.productId,
              item_name: item.productName,
              price: item.itemSubtotal,
              quantity: item.quantity
            })) || []
        },
        value: this.cart?.total || 0,
        coupon: this.cart?.offer?.code || '',
        currency: 'USD',

        order_method: (this.cart?.handoffMode as HandoffMode) || 'carryout', //'carryout'|'curbside'|'delivery'|'dinein',
        order_time: this.cart?.asap ? 'asap' : 'later',
        registered: this.authState?.isAuthenticated || false,
        store_name: this.storeInfoState?.storeInfo?.storeDetails.name || '',
        subtotal: this.cart?.subtotal || 0
      });

      const passwordRequirements =
        await this.passwordRequirementsService.getPasswordRequirements();

      this.passwordRequirements$.next(
        passwordRequirements ?? this.fallBackPasswordRequirement
      );

      const RoundupCharityData =
        await this.roundupCharityService.getRoundupCharityData();

      this.roundupCharity$.next(
        RoundupCharityData ?? this.fallBackRoundupCharityResponse
      );

      const emailDomainValidations =
        await this.emailDomainValidationService.getEmailDomainValidations();

      this.emailDomainValidations$.next(emailDomainValidations);

    } catch (error) {
      console.error(
        'Initialization error in ConnectedCheckoutComponent:',
        error
      );
    }
  }

  private subscribeToLocationDetailsState(): void {
    const locationDetailsState$ = this.store
      .select(LocationDetailsFeature.selectLocationDetailsState)
      .pipe(filter<LocationDetailsFeatureState>(Boolean));

    this.subscription.add(
      locationDetailsState$.subscribe((state) => {
        this.locationDetailsState = state;
      })
    );
  }

  private async loadData() {
    return new Promise<void>((resolve, reject) => {
      this.subscription.add(
        this.route.data
          .pipe(
            filter((data) => data[ACTIVATED_ROUTE_SNAPSHOT]),
            tap(() => (this.isLoading = true)),
            switchMap((data) =>
              this.pageService.load$(data[ACTIVATED_ROUTE_SNAPSHOT])
            )
          )
          .subscribe({
            next: () => {
              this.isLoading = this.isPlaceOrderCalled;
              resolve();
            },
            error: (error) => reject(error)
          })
      );
    });
  }

  async subscribeToRegionalConfigState(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.subscription.add(
        this.regionalConfig$.subscribe({
          next: (state) => {
            this.regionalConfigState = state;
            this.partialOutageModalComponent?.showModal(state);
            resolve();
          },
          error: (err) => {
            reject(err);
          }
        })
      );
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  showRoundUp(): boolean {
    return (
      this.storeInfoState?.storeInfo?.storeDetails?.storeFees?.some(
        (storeFee) => storeFee.categoryName === 'donation'
      ) || false
    );
  }

  isTippingAvailable(): boolean {
    return new TipSelectionPipe().transform(
      this.cart,
      this.storeInfoState?.storeInfo?.storeDetails ?? null
    );
  }

  isCartInAsapMode(): boolean {
    return <boolean>this.cart?.asap;
  }

  handleDeliveryInstuctionsData(
    values: DeliveryInstructionsDataWithErrors
  ): void {
    this.deliveryFormInfoData$.next(values);
    this.deliveryInstructionsData = values;
  }

  expressPayTouched = false;
  handleExpressPayClicked(paymentProvider: string): void {
    if (this.paymentMethod.getSelectedNonExpressPaymentMethods().length > 0) {
      this.splitPaymentsModalDescription =
        this.getSplitPaymentsModalDescription(paymentProvider);
      this.modalService.open(this.splitPaymentsModal, {
        windowClass: 'common-modal',
        centered: true,
        size: 'sm'
      });
    } else {
      this.expressPayTouched = true;
      const signupFormIsInvalid =
        this.contactInfoData?.errors &&
        this.hasFormErrors(this.contactInfoData?.errors);
      this.contactInfoFormValid = !signupFormIsInvalid;
    }
  }

  private getSplitPaymentsModalDescription(paymentProvider: string): string {
    const selectedNonExpressPaymentMethods =
      this.paymentMethod.getSelectedNonExpressPaymentMethods();
    const uniquePaymentTypes = [
      ...new Set(
        selectedNonExpressPaymentMethods.map(
          (paymentMethod) => paymentMethod.paymentType
        )
      )
    ];
    if (uniquePaymentTypes.length > 1) {
      return `We're sorry but we can't split payments between gift cards, credit cards and ${paymentProvider}. If you want to pay for the full order with ${paymentProvider}, please unselect your gift card(s) and credit card and try again.`;
    } else {
      switch (uniquePaymentTypes[0]) {
        case PaymentType.giftCard: {
          return `We're sorry but we can't split payments between gift cards and ${paymentProvider}. If you want to pay for the full order with ${paymentProvider}, please unselect your gift card(s) and try again.`;
        }
        case PaymentType.creditCard: {
          return `We're sorry but we can't split payments between credit cards and ${paymentProvider}. If you want to pay for the full order with ${paymentProvider}, please unselect your credit card and try again.`;
        }
        case PaymentType.inStore: {
          return `We're sorry, but we can't split payments between pay in restaurant and ${paymentProvider}. Please unselect pay in restaurant and try again`;
        }
        default: {
          return '';
        }
      }
    }
  }

  handleContactInfoData(values: GuestSignupFormDataWithErrors): void {
    this.contactInfoData$.next(values);
    this.contactInfoData = values;
  }

  handlePaymentDataValidity(value: boolean): void {
    this.placeOrderBtnClicked = false; //resetting on every payment type toggle
    if (this.ifExpressPay) {
      //bypassing payment form for Apple Pay
      this.paymentFormValid = true;
    } else {
      this.paymentFormValid = value;
    }
  }

  handleGiftCardsValidity(value: boolean): void {
    this.giftCardsValid = value;
  }

  handlePaymentDataSelected(value: boolean): void {
    this.paymentSelected = value;
    this.paymentsSelectedSubject.next(
      this.paymentMethod?.getSelectedNonExpressPaymentMethods() || []
    );
    if (value) {
      this.digitalWalletPaymentType$.next(null)
      this.changePlaceOrderBtn = false;
    }
  }

  async handleExpressPay(paymentInfo: PaymentInfo) {
    this.ifExpressPay = true;
    this.contactInfoFormValid = false;

    const deliveryInstructionsObj = {
      secondaryAddress: this.deliveryInstructionsData?.secondaryAddress || null,
      isContactlessDelivery:
        this.deliveryInstructionsData?.isContactlessDelivery || false,
      deliveryInstructions:
        this.deliveryInstructionsData?.deliveryInstructions || null,
      deliveryTip: 0 // TODO get deliveryTip from cartData
    };

    const userSession = {
      userSession: {
        loginInfo: {
          loginProvider: this.getLoginProvider(this.authState?.user?.id_token)
        }
      }
    };
    const sendMeOffers = {
      sendmeoffers: this.contactInfoData?.sendOffers || false
    };

    if (this.contactInfoData?.sendOffers) {
      this.analyticsService.logGaEvent({
        event: 'opt_in_email'
      });
    } else {
      this.analyticsService.logGaEvent({
        event: 'opt_out_email'
      });
    }

    const ravelinDeviceId = await this.getDeviceId();
    const placeOrderData: OrderSubmitRequest = {
      action: 'submit-order',
      request: {
        cartId: this.cart?.cartId || '',
        transactionId: uuidv4(),
        guest: {
          firstName: this.contactInfoData?.firstName || '',
          lastName: this.contactInfoData?.lastName || '',
          emailAddress: this.userEmail ?? this.contactInfoData?.email ?? '',
          phoneNumber: this.contactInfoData?.phone || '',
          password: this.contactInfoData?.password || ''
        },
        acceptLanguage: 'en-US',
        source: {
          platform: 'web',
          version: this.config.appVersion
        },
        delivery: this.isDelivery() ? deliveryInstructionsObj : undefined,
        ...paymentInfo,
        ...(this.isUserLoggedIn && { ...userSession }),
        ...(!this.isUserLoggedIn && { ...sendMeOffers }),
        ravelinDeviceId
      }
    };

    this.subscribeToOrderState();
    try {
      this.orderService.placeOrder(
        placeOrderData,
        tap((res) => {
          this.handleErrorsOnPlacingOrder(res, paymentInfo.payments);
        })
      );
    } catch (err) {
      this.paymentMethod?.getPlaceOrderResponse();
    }
  }

  async handleLogin() {
    this.isLoadingSubject.next(true);
    await this.authService.login(this.router.url);
    this.isLoadingSubject.next(false);
  }

  private handleErrorsOnPlacingOrder(res: MaybeResponse<Order>, payments: OrderSubmitRequestPayment[]) {
    if (res.type === OrderSubmitAction.orderFailed) {
      if (includes(OtherPaymentFailedType, res.errorCode)) {
        // turn off all payment methods
        this.paymentMethod.resetPaymentMethodForm();
      } else if (includes(FiservPaymentFailedType, res.errorCode)) {
        // turn off non-vaulted credit cards
        this.paymentMethod.turnOffPaymentMethod(PaymentType.creditCard, payments);
      }
    }
  }

  onPlaceOrderBtnClick() {
    this.placeOrderBtnClicked = true;
    this.ifExpressPay = false;
    // form sections to validate sequentially
    const deliveryModeFormsToValidate: CheckoutFormName[] = [
      'deliveryInstructionsForm'
    ];
    const commonFormsToValidate: CheckoutFormName[] = [
      'signupForm',
      'paymentForm'
    ];
    const formsToValidate = this.isDelivery()
      ? deliveryModeFormsToValidate.concat(commonFormsToValidate)
      : commonFormsToValidate;
    const areFormsInvalid = this.areFormsInvalid(formsToValidate);

    // if forms valid
    if (!areFormsInvalid) {
      this.deliveryInstructionsFormValid = true;
      this.contactInfoFormValid = true;
      this.paymentFormValid = true;
      this.placeOrder();
    }
  }

  // check if form has outstanding validation errors
  hasFormErrors(
    formErrors: Record<string, ValidationErrors | null> | undefined
  ): boolean {
    if (Object.entries(formErrors || {}).length > 0) {
      return true;
    }
    return false;
  }

  public isDelivery(): boolean {
    return this.cartState?.cart?.handoffMode.toLowerCase() === 'delivery';
  }

  private scrollToSection(elementId: string, extraOffset = 0) {
    const element = document.getElementById(elementId);
    const headerOffset = 45 + extraOffset;
    const elementPosition = element?.getBoundingClientRect().top || 0;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      behavior: 'smooth',
      top: offsetPosition
    });
  }

  areFormsInvalid(forms: CheckoutFormName[]) {
    return forms.some((form) => {
      switch (form) {
        case 'deliveryInstructionsForm': {
          const deliveryInstructionsFormIsInvalid =
            this.deliveryInstructionsData?.errors &&
            this.hasFormErrors(this.deliveryInstructionsData?.errors);
          if (deliveryInstructionsFormIsInvalid) {
            this.notificationService.showError(
              'Oops, it seems we have some invalid delivery information. Please correct any issues to proceed.'
            );
            this.deliveryInstructionsFormValid = false;
            this.scrollToSection('delivery-instructions-form', 40);
          }
          return deliveryInstructionsFormIsInvalid;
        }

        case 'signupForm': {
          const signupFormIsInvalid =
            this.contactInfoData?.errors &&
            this.hasFormErrors(this.contactInfoData?.errors);
          if (signupFormIsInvalid) {
            this.notificationService.showError(
              'Oops, it seems we are missing some contact information. Please finish the info section to proceed.'
            );
            this.analyticsService.logGaEvent({
              event: 'checkout_error',
              error_reason: 'contact_form_validation'
            });
            this.contactInfoFormValid = false;
            this.scrollToSection('guest-signup-form');
          } else {
            this.contactInfoFormValid = true;
          }
          return signupFormIsInvalid;
        }

        case 'paymentForm': {
          const signupFormIsInvalid =
            !this.paymentFormValid || !this.paymentSelected;
          if (signupFormIsInvalid) {
            this.notificationService.showError(
              'Please select a payment method.'
            );
            this.analyticsService.logGaEvent({
              event: 'checkout_error',
              error_reason: 'payment_method_required'
            });
            this.paymentFormValid = false;
            this.scrollToSection('payment-wrapper');
          }
          if (!this.giftCardsValid) {
            this.scrollToSection('payment-wrapper');
          }
          return !this.giftCardsValid || signupFormIsInvalid;
        }

        default:
          return false;
      }
    });
  }

  async getDeviceId() {
    const ravelin = new Ravelin({ key: this.config.fiserv.ravelinApiKey });
    return await ravelin.core.id();
  }

  async placeOrder() {
    const deliveryInstructionsObj = {
      secondaryAddress: this.deliveryInstructionsData?.secondaryAddress || null,
      isContactlessDelivery:
        this.deliveryInstructionsData?.isContactlessDelivery || false,
      deliveryInstructions:
        this.deliveryInstructionsData?.deliveryInstructions || null,
      deliveryTip: 0 // TODO get deliveryTip from cartData
    };
    this.numberOfTimesPlaceOrderTriggered = 0;
    const paymentInfo = await firstValueFrom(
      this.paymentMethod.getPaymentInfo()
    );
    this.numberOfTimesPlaceOrderTriggered++;
    const userSession = {
      userSession: {
        loginInfo: {
          loginProvider: this.getLoginProvider(this.authState?.user?.id_token)
        }
      }
    };

    const sendMeOffers = {
      sendmeoffers: this.contactInfoData?.sendOffers || false
    };

    if (this.contactInfoData?.sendOffers) {
      this.analyticsService.logGaEvent({
        event: 'opt_in_email'
      });
    } else {
      this.analyticsService.logGaEvent({
        event: 'opt_out_email'
      });
    }

    const ravelinDeviceId = await this.getDeviceId();
    const placeOrderData: OrderSubmitRequest = {
      action: 'submit-order',
      request: {
        ...paymentInfo,
        acceptLanguage: this.navigator.language || 'en-US',
        source: {
          platform: 'web',
          version: this.config.appVersion
        },
        guest: {
          firstName: this.contactInfoData?.firstName || '',
          lastName: this.contactInfoData?.lastName || '',
          emailAddress: this.contactInfoData?.email || '',
          phoneNumber: this.contactInfoData?.phone || '',
          password: this.contactInfoData?.password || ''
        },
        cartId: this.cart?.cartId || '',
        transactionId: uuidv4(),
        delivery: this.isDelivery() ? deliveryInstructionsObj : undefined,
        ...(this.isUserLoggedIn && { ...userSession }),
        ...(!this.isUserLoggedIn && { ...sendMeOffers }),
        ravelinDeviceId
      }
    };

    // handle page loading when place order ws api is in action
    this.subscribeToOrderState();
    if (this.numberOfTimesPlaceOrderTriggered === 1) {
      try {
        this.orderService.placeOrder(
          placeOrderData,
          tap((res) => {
            this.handleErrorsOnPlacingOrder(res, paymentInfo.payments);
            if (
              res.type === OrderSubmitAction.orderScheduled &&
              res.data?.cart?.handoffMode === HandoffMode.delivery &&
              res.data?.cart?.deliveryAddress?.longitude !== null &&
              res.data?.cart?.deliveryAddress?.latitude !== null
            ) {
              this.saveDeliveryAddress(res);
            }
          })
        );
      } catch (err) {
        await this.paymentMethod?.getPlaceOrderResponse();
      }
    }
  }

  payWithDigitalWallet(paymentType: string) {
    if (paymentType) {
      this.changePlaceOrderBtn = true;
      this.digitalWalletPaymentType$.next(paymentType);
    }
  }

  private saveDeliveryAddress(res: MaybeResponse<Order>) {
    const deliveryAddressRequest: DeliveryAddressPostRequest = {
      countryCode: res.data?.cart?.deliveryAddress?.countryCode || '',
      latitude: res.data?.cart?.deliveryAddress?.latitude || 0,
      longitude: res.data?.cart?.deliveryAddress?.longitude || 0,
      locality: res.data?.cart?.deliveryAddress?.locality || '',
      postalCode: res.data?.cart?.deliveryAddress?.postalCode || '',
      region: res.data?.cart?.deliveryAddress?.region || '',
      streetAddress: res.data?.cart?.deliveryAddress?.streetAddress || '',
      deliveryInstructions:
        this.deliveryInstructionsData?.deliveryInstructions || null,
      secondaryStreetAddress:
        res.data?.cart?.deliveryAddress?.secondaryAddress || null
    };
    this.userAccountService.saveDeliveryAddress(deliveryAddressRequest);
  }

  private subscribeToStoreInfoState() {
    const storeInfo$ = this.store
      .select(StoreInfoFeature.selectStoreInfoState)
      .pipe(filter<StoreInfoFeatureState>(Boolean));

    this.subscription.add(
      storeInfo$.subscribe((state) => {
        this.storeInfoState = state;
        this.fulfillmentTime = state.storeInfo?.fulfillmentTimes ?? null;
      })
    );
  }

  private subscribeToCartState(): void {
    const cartState$ = this.store
      .select(CartFeature.selectCartState)
      .pipe(filter<CartFeatureState>(Boolean));

    this.subscription.add(
      cartState$.subscribe((state) => {
        this.cartState = state;
        this.cart = state.cart;

        this.deliveryInstructionData = {
          deliveryInstructions:
            this.cart?.deliveryAddress?.deliveryInstructions || '',
          secondaryAddress: this.cart?.deliveryAddress?.secondaryAddress || '',
          isContactlessDelivery: false
        };

        this.setNumOfItemsInCart();
        this.ftForLater();
      })
    );
  }

  private setNumOfItemsInCart(): void {
    // set up the number of items in cart
    this.numItems = 0;
    if (this.cart?.items && Array.isArray(this.cart.items)) {
      this.cart.items.forEach((cartItem) => {
        this.numItems += cartItem.quantity;
      });
    }
  }

  private subscribeToOrderState(): void {
    const orderState$ = this.store
      .select(OrderFeature.selectOrderState)
      .pipe(filter<OrderFeatureState>(Boolean));

    this.subscription.add(
      orderState$.subscribe((state) => {
        this.orderState = state;
        this.isPlaceOrderCalled = this.orderState.isLoading;
        this.isLoading = this.isPlaceOrderCalled;
      })
    );
  }

  private async subscribeToAuthState() {
    const authState$ = this.store
      .select(AuthFeature.selectAuthState)
      .pipe(filter<AuthFeatureState>(Boolean));

    this.subscription.add(
      authState$.subscribe((state) => {
        this.userLoggedIn = state.isAuthenticated;
      })
    );

    authState$
      .pipe(
        switchMap((state) => {
          this.authState = state;
          return state.isAuthenticated
            ? this.userAccountService.getUserAccount()
            : of(null);
        })
      )
      .subscribe((userDetails) => {
        this.userEmail = userDetails?.email;
        this.isUserLoggedIn = userDetails?.email !== undefined;
        this.userDetails = userDetails;
        if (this.isUserLoggedIn) {
          this.getVaultedCards();
        }
      });
  }

  async getVaultedCards() {
    this.vaultedCards$ = from(
      this.paymentWorkflowService.getVaultedGiftAndCreditCards()
    );
  }

  private ftForLater() {
    const timeZone = getOr(
      '???',
      ['storeInfo', 'storeDetails', 'timeZone'],
      this.storeInfoState
    );
    this.laterTime = !this.isCartInAsapMode()
      ? getLaterTime(this.cart?.fulfillmentTime, timeZone)
      : '';
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private getLoginProvider(id_token: string | undefined): string {
    if (id_token != null) {
      const decoded_token = jwtDecode(id_token);
      if ((decoded_token as any)?.iss) {
        return 'WINGSTOP';
      }
      if ((decoded_token as any)?.ssoUser) {
        return (decoded_token as any)?.ssoUser;
      }
    }
    return '';
  }
}
